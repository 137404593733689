import React from "react";
import {
  Container,
  ContentIcon,
  WrapperFooter,
  ContentMenu,
  MenuContainer,
  MenuItem,
  ContainerCentralSocial,
  ContentCentral,
  ContentLocales,
  Title,
  ContentContact,
  Contact,
  ContentSocialMedia,
  ContentMedias,
  ContainerPolitic,
  ContentPolitic,
  PoliticItem,
  ContainerInfoCompany,
  TextCompany,
} from "./styles";
import { ReactComponent as LogoMulvi } from "../../../../assets/logo-mulvi.svg";
import { ReactComponent as Instagram } from "../../../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../../../assets/facebook.svg";
import { ReactComponent as LinkedIn } from "../../../../assets/linkedin.svg";
import { ReactComponent as FooterIcon } from "../../../../assets/footer-icon.svg";
import PLDPolicy from "../../../../assets/pdf/Politica-de-PLD-Novo.pdf";
import TermsOfUse from "../../../../assets/pdf/terms-of-use.pdf";
import ExternalCybersecurityPolicy from "../../../../assets/pdf/Politica-Externa-de-Seguranca-Cibernetica-da-MULVI.pdf";

const Footer = () => {
  return (
    <Container className="footer">
      <ContentIcon>
        <FooterIcon />
      </ContentIcon>
      <WrapperFooter>
        <ContentMenu>
          <LogoMulvi className="logo-mulvi" />
          <MenuContainer>
            <MenuItem href="#benefits">Vantagens</MenuItem>
            <MenuItem href="#rate">Taxas</MenuItem>
            <MenuItem href="#solutions">Soluções</MenuItem>
            <MenuItem href="#contents">Conteúdos</MenuItem>
            <MenuItem href="#faq">Perguntas Frequentes</MenuItem>
            <MenuItem href="#whoWeAre">Quem Somos</MenuItem>
          </MenuContainer>
        </ContentMenu>
        <ContainerCentralSocial>
          <ContentCentral>
            <Title className="central-attendance">Central de Atendimento</Title>
            <ContentContact>
              <ContentLocales>
                <Contact>Atendimento Mulvi Pay:</Contact>
                <span className="central-contact">0800 931 7339</span>
              </ContentLocales>

              <ContentLocales>
                <Contact>Email:</Contact>
                <span className="central-contact">
                  relacionamento@mulvi.com.br
                </span>
              </ContentLocales>
            </ContentContact>
          </ContentCentral>
          <ContentSocialMedia>
            <Title className="title-talk">Vem conversar com a gente</Title>
            <ContentMedias>
              <a
                href={"https://instagram.com/mulvipay"}
                target={"_blank"}
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                href={"https://www.facebook.com/profile.php?id=100084447383790"}
                target={"_blank"}
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href={"https://www.linkedin.com/company/mulvi/"}
                target={"_blank"}
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
            </ContentMedias>
          </ContentSocialMedia>
        </ContainerCentralSocial>
        <ContainerPolitic>
          <ContentPolitic>
            <PoliticItem
              href="/rights-holder"
              target={"_blank"}
            >
              Direitos do Titular
            </PoliticItem>
            <PoliticItem 
              href={"/use-terms"} 
              target={"_blank"}
            >
              Termos de uso
            </PoliticItem>
            <PoliticItem
              href="https://privacidade.banese.com.br/privacidade/politica.html"
              target={"_blank"}
            >
              Política de Privacidade
            </PoliticItem>
            <PoliticItem 
              href={"/security-policy"}
              target={"_blank"}
            >
              Política de Segurança
            </PoliticItem>
            <PoliticItem href={ExternalCybersecurityPolicy} target={"_blank"}>
              Política de Segurança Cibernética
            </PoliticItem>
            <PoliticItem href={PLDPolicy} target={"_blank"}>
              Política de PLD
            </PoliticItem>
            <PoliticItem
              href="https://www.canaldedenuncia.com.br/mulvi/"
              target={"_blank"}
            >
              Canal de Denúncia
            </PoliticItem>
          </ContentPolitic>
        </ContainerPolitic>
      </WrapperFooter>
      <ContainerInfoCompany>
        <TextCompany>
          Mulvi Instituição de Pagamento SA sob n 03.847.413/0001-02, com sede
          na Cidade de Aracaju, Estado de Sergipe
        </TextCompany>
      </ContainerInfoCompany>
    </Container>
  );
};

export default Footer;
// export default header mto iyut
