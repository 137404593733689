import React, { useEffect } from "react"

const SecurityPolicy = () => {
    const height = window.innerHeight

    useEffect(() => {
        const body = document.querySelector('body')
        if (body) body.style.overflowY = 'hidden'
    }, [])

  return (
    <div>
        <iframe src='https://dpo.privacytools.com.br/policy-view/O5GVgPWVm/0/poli%CC%81tica-de-seguranc%CC%A7a-da-informac%CC%A7a%CC%83o/pt_BR?s=1686916349632' width='100%' height={`${height}px`} />
    </div>
  )
}

export default SecurityPolicy