import React from "react";
import {
  Container,
  ContentImg,
  SingleSolutionsImg,
  ContentSolution,
  Title,
  SolutionText,
  ContainerBenefitsSolutions,
  FirstContent,
  SecondContent,
  FirstContainer,
  SecondContainer,
  MulviDestyContainer,
  WrapperImg,
  MulviDestyImg,
  ContentLogos,
  ContentInfoMulviDesty,
  InfoMulviDestyText,
  ContentRent,
  RentText,
  TitleListMulviDesty,
  ListMulviDesty,
  ListItemMulviDesty,
  CallBackMulviDesty,
  ContentApp,
  ContentImgCallBack,
  QrCodeImg,
} from "./styles";
import SingleSolutions from "../../../../assets/single-solutions.png";
import { ReactComponent as MulviPayLogo } from "../../../../assets/mulvi-pay-second.svg";
import { ReactComponent as DestyLogo } from "../../../../assets/desty.svg";
import MulviDesty from "../../../../assets/mulvi-desty.png";
import { ReactComponent as GooglePlay } from "../../../../assets/google-play.svg";
import { ReactComponent as AppStore } from "../../../../assets/app-store.svg";
import QrCodeApple from "../../../../assets/qrcode-apple.png";
import QrCodeGoogle from "../../../../assets/qrcode-google.png";
import BenefitisSolution from "../../../BenefitsSolutions";
import MulviBtnBkp from "../../../MulviBtnBkp";
import {
  ListBenefitsSolutionsFirstFirstGroup,
  ListBenefitsSolutionsFirstSecondGroup,
  ListBenefitsSolutionsSecond,
} from "../../../../utils/lists";
import Machine from "../../../../assets/machine.png";
import MachineMobile from "../../../../assets/mobile/machine-mobile.png";

const SolutionsMulviDesty = () => {
  return (
    <Container id="solutions">
      {/* <MulviDestyContainer>
        <ContentLogos>
          <MulviPayLogo className="mulvi-logo" />{" "}
          <span className="plus">+</span> <DestyLogo className="desty-logo" />
          {"\n"}
          <span className="attention-strong">é mais dinheiro no bolso.</span>
          <ContentInfoMulviDesty>
            <InfoMulviDestyText>
              A parceria da Mulvi Pay com o Desty, o banco digital do Banese,
              traz oportunidades e benefícios únicos para o seu negócio.
            </InfoMulviDestyText>

            <TitleListMulviDesty>
              Entenda como obter esse beneficio:
            </TitleListMulviDesty>
            <ListMulviDesty>
              <ListItemMulviDesty>
                1. Após aprovado seu cadastro na Mulvi Pay, baixe o APP Desty na sua loja de aplicativos;
              </ListItemMulviDesty>
              <ListItemMulviDesty>
                2. Cadastre-se no Desty e aguarde a confirmação de abertura de conta digital;
              </ListItemMulviDesty>
              <ListItemMulviDesty>
                3. Entre em contato com nossa central de atendimento 0800 931 7339 e informe sua nova Conta Desty como domicilio bancário.
              </ListItemMulviDesty>
            </ListMulviDesty>
            <CallBackMulviDesty>
              <TitleListMulviDesty className="title-callback">
                Baixe o app e crie sua conta no Desty:
              </TitleListMulviDesty>
              <ContentImgCallBack>
                <ContentApp>
                  <QrCodeImg src={QrCodeGoogle} />
                  <GooglePlay className="google-play" />
                </ContentApp>
                <ContentApp>
                  <QrCodeImg src={QrCodeApple} />
                  <AppStore />
                </ContentApp>
              </ContentImgCallBack>
            </CallBackMulviDesty>
          </ContentInfoMulviDesty>
        </ContentLogos>
        <WrapperImg>
          <MulviDestyImg src={MulviDesty} alt="Mulvi-Desty" />
        </WrapperImg>
      </MulviDestyContainer> */}
      <FirstContainer>
        <ContentImg>
          <SingleSolutionsImg
            src={SingleSolutions}
            alt="soluções únicas"
            className="business-bg"
          />
        </ContentImg>
        <ContentSolution>
          <Title className="first-title">
            Soluções únicas para o{" "}
            <span className="attention-strong">crescimento do seu negócio</span>
          </Title>
          <SolutionText>
            Tudo que você precisa para fechar negócios sem perder tempo pra
            fazer sua empresa decolar.
          </SolutionText>
          <ContainerBenefitsSolutions className="container-benefits-first">
            <FirstContent className="first-content-first">
              <BenefitisSolution data={ListBenefitsSolutionsFirstFirstGroup} />
            </FirstContent>
            <SecondContent className="second-content-first">
              <BenefitisSolution data={ListBenefitsSolutionsFirstSecondGroup} />
            </SecondContent>
          </ContainerBenefitsSolutions>
          <MulviBtnBkp />
        </ContentSolution>
      </FirstContainer>
      <SecondContainer>
        <ContentSolution>
          <Title className="second-title">
            Mais do que uma maquininha,{" "}
            <span className="attention-strong">
              somos o parceiro que movimenta o seu negócio.
            </span>
          </Title>
          <ContentRent>
            <RentText>
              <span className="attention-strong">
                Sua maquininha pode sair com custo zero!
              </span>
              <br></br>Quanto mais você faturar, mais desconto você ganha.
              Procure nossos consultores Mulvi Pay
            </RentText>
          </ContentRent>
          <ContainerBenefitsSolutions className="container-benefits-second">
            <FirstContent className="first-content-second">
              <BenefitisSolution data={ListBenefitsSolutionsSecond} />
            </FirstContent>
          </ContainerBenefitsSolutions>
        </ContentSolution>
        <ContentImg className="bg-machine">
          <SingleSolutionsImg
            src={Machine}
            alt="soluções únicas"
            className="machine-bg"
          />
          <SingleSolutionsImg
            src={MachineMobile}
            alt="soluções únicas"
            className="machine-bg-mobile"
          />
        </ContentImg>
      </SecondContainer>
    </Container>
  );
};

export default SolutionsMulviDesty;
