import styled from "styled-components";

export const Container = styled.div`
    #privacy-tools-app {
        .pvt-main.custom {
            overflow-x: hidden !important;
        }
    }  

    .footer {
        margin-top: -6px;
    }
`