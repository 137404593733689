import React, { useEffect } from "react"

const UseTerms = () => {
  const height = window.innerHeight

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) body.style.overflowY = 'hidden'
  }, [])

  return (
    <div>
      <iframe 
        src='https://dpo.privacytools.com.br/policy-view/W2Gd4DlEX/3/termos-de-uso/pt_BR?s=1686918486527' 
        width='100%' 
        height={`${height}px`} 
      />
    </div>
  )
}

export default UseTerms