import React from "react";
import { Container, Button } from "./styles";

const MulviBtn = ({ ...rest }) => {
  return (
    <Container className="btn-mulvi" {...rest}>
      <Button
        href="https://app.mulvipay.com.br/PortalLojista3/QueroSerMulvi/DadosIniciais"
        id="queroser-menu03"
      >
        Quero ser mulvi
      </Button>
    </Container>
  );
};

export default MulviBtn;
