import React from "react"
import { Container } from './styles'
import Menu from "../../components/Menu"
import Footer from "../../components/sections/HomeSections/Footer"

const RightsHolder = () => {
  const height = window.innerHeight

  return (
    <Container>
      <Menu />
      <iframe 
        src='https://portal.privacytools.com.br/portal/14bfccc9-3f45-47ed-b557-14e497be2e9b#toolbar=0&amp;navpanes=0' 
        width='100%' 
        height={`${height}px`} 
        style={{marginTop: '120px'}}
      />
      <Footer />
    </Container>
  )
}

export default RightsHolder